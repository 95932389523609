<template>
    <label v-if="filteredResponsibilityTypes">
        <multi-select
            label='name'
            select-label=""
            selected-label=""
            deselect-label=""
            :multiple="false"
            :searchable="false"
            :show-labels="true"
            :disabled='disabled'
            v-model="currentValue"
            :close-on-select="true"
            :options="filteredResponsibilityTypes"
            placeholder="Тип ответственности"
        ></multi-select>
    </label>
</template>

<script>
    import cloneDeep from 'lodash/cloneDeep';
    import MultiSelect from 'vue-multiselect';

    import { SALARY_ENDPOINT } from '../../utils/endpoints';

    export default {
        name: "SalaryBonusResponsibilityTypeSelect",
        components: { MultiSelect },
        props: {
            value: {
                type: Object,
                default: () => null
            },
            disabled: { type: Boolean, default: false },
            salaryBonusCalculationTypeId: {
                type: Number,
                default: () => null
            }
        },
        watch: {
            value() {
                if ( this.isDiff() ) {
                    this.setValue();
                }
            },
            currentValue() {
                if ( this.isDiff() ) {
                    this.$emit( 'input', this.currentValue );
                }
            }
        },
        data() {
            return {
                currentValue: null,
                responsibilityTypes: []
            };
        },
        computed: {
            filteredResponsibilityTypes() {
              return this.salaryBonusCalculationTypeId
                  ? this.responsibilityTypes.filter(el => el.salary_bonus_calculation_type_id === this.salaryBonusCalculationTypeId)
                  : this.responsibilityTypes;
            }
        },
        methods: {
            isDiff() {
                return JSON.stringify( this.value ) !== JSON.stringify( this.currentValue );
            },
            setValue() {
                this.currentValue = cloneDeep( this.value );
            },
            fetchResponsibilityTypes() {
                this.responsibilityTypes = [];

                this.$http
                    .get( SALARY_ENDPOINT + '/bonus/responsibility-types' )
                    .then( response => this.responsibilityTypes = response.data.data.data )
                    .then( () => this.setValue() );
            }
        },
        created() {
            this.fetchResponsibilityTypes();
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
