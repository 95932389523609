<template>
    <multi-select
        label='name'
        select-label=""
        selected-label=""
        deselect-label=""
        :multiple="false"
        :searchable="false"
        :show-labels="true"
        :disabled='disabled'
        v-model="currentValue"
        :close-on-select="true"
        placeholder="Тип проверки"
        @input="onInput"
        :options="checkTypes"
    ></multi-select>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import MultiSelect from 'vue-multiselect';

import {SALARY_ENDPOINT} from '@utils/endpoints';

export default {
    name: "SalaryBonusCheckTypeSelect",
    components: {MultiSelect},
    props: {
        value: {type: Object, default: () => null},
        disabled: {type: Boolean, default: false}
    },
    watch: {
        value() {
            if (this.isDiff()) {
                this.setValue();
            }
        },
        currentValue() {
            if (this.isDiff()) {
                this.$emit('input', this.currentValue);
            }
        }
    },
    data() {
        return {
            currentValue: null,
            checkTypes: []
        };
    },
    methods: {
        onInput(selectedOption) {
            this.$emit('input', selectedOption)
        },
        isDiff() {
            return JSON.stringify(this.value) !== JSON.stringify(this.currentValue);
        },
        setValue() {
            this.currentValue = cloneDeep(this.value);
        },
        fetchCheckTypes() {
            this.checkTypes = [];

            let params = {with: []};

            this.$http
                .get(SALARY_ENDPOINT + '/bonus/check-types', {params})
                .then(response => this.checkTypes = response.data.data.data)
                .then(() => this.setValue());
        }
    },
    created() {
        this.fetchCheckTypes();
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
