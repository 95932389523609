<template>
    <b-container fluid class='container-margin' v-if='form'>
        <b-row class='my-1 text-left row-margin'>
            <b-col md='12'>
                <label for='field_calculation_type'><b>Тип расчета</b></label>
                <salary-bonus-calculation-type-select v-model='form.calculationType' style='display:inline;'>
                </salary-bonus-calculation-type-select>
            </b-col>
        </b-row>
        <b-row class='my-1 text-left row-margin'>
            <b-col md='12'>
                <label for='field_responsibility_type'><b>Тип ответственности</b></label>
                <salary-bonus-responsibility-type-select
                    :salaryBonusCalculationTypeId="form.calculationType && form.calculationType.id"
                    v-model='form.responsibilityType'
                    style='display:inline;'>
                </salary-bonus-responsibility-type-select>
            </b-col>
        </b-row>
        <b-row class='my-1 text-left row-margin'>
            <b-col md='12'>
                <label for='field_work_position'><b>Должность</b></label>
                <work-position-select v-model='form.workPosition' style='display:inline-block;'></work-position-select>
            </b-col>
        </b-row>

        <b-row class='my-1 text-left row-margin'>
            <b-col md='12'>
                <label for='field_valid_from'><b>Активен с</b></label>

                <date-time-picker
                    locale='ru'
                    input-size='sm'
                    label='Выберите дату'
                    id='salary_bonus_datepicker'

                    :no-label='true'
                    :no-header='true'
                    :only-date='true'
                    :no-button='true'
                    :auto-close='true'
                    :no-clear-button='true'

                    format='YYYY-MM'
                    formatted='YYYY-MM'

                    v-model='form.valid_from'
                ></date-time-picker>
            </b-col>
        </b-row>

        <b-row class='my-1 text-left row-margin' v-if="form.calculationType && form.calculationType.key === 'kpi_sanitary_rate'">
            <b-col md="12">
                <label for="field_check_type_kpi_sanitary_rate">Тип проверки</label>
                <salary-bonus-check-type-select style='display:inline-block;' v-model="form.salaryBonusCheckType"/>
            </b-col>
        </b-row>
        <b-row class='my-1 text-left row-margin' v-if="form.calculationType && form.calculationType.key === 'kpi_sanitary_rate'">
            <b-col md="12">
                <label for="field_addition_kpi_sanitary_rate">Тип надбавки</label>
                <salary-bonus-addition-select style='display:inline-block;' v-model="form.salaryBonusAddition"/>
            </b-col>
        </b-row>

        <b-row class='my-1 text-left row-margin' v-for='( record, index ) in form.config' :key='index'>
            <b-col md='12'>
                <label :for='`field_` + record.attribute.key'><b>{{ record.attribute.name }}</b></label>

                <b-form-input
                    min='0.01'
                    step='0.01'
                    max='1000.00'
                    type='number'
                    v-model='record.value'
                    placeholder='Введите значение'
                ></b-form-input>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import forEach from 'lodash/forEach';
    import cloneDeep from 'lodash/cloneDeep';

    import DateTimePicker from 'vue-ctk-date-time-picker';

    import WorkPositionSelect from '../../_common/WorkPositionSelect';
    import SalaryBonusCalculationTypeSelect from '../../_common/SalaryBonusCalculationTypeSelect';
    import SalaryBonusResponsibilityTypeSelect from '../../_common/SalaryBonusResponsibilityTypeSelect';
    import SalaryBonusAdditionSelect from "@components/_common/SalaryBonusAdditionSelect";
    import SalaryBonusCheckTypeSelect from "@components/_common/SalaryBonusCheckTypeSelect";

    export default {
        name: "SalaryBonusForm",
        components: {
            DateTimePicker,
            WorkPositionSelect,
            SalaryBonusCalculationTypeSelect,
            SalaryBonusResponsibilityTypeSelect,
            SalaryBonusAdditionSelect,
            SalaryBonusCheckTypeSelect,
        },
        props: {
            value: { type: Object, default: () => null }
        },
        computed: {
            isDiff() {
                return JSON.stringify( this.form ) !== JSON.stringify( this.value );
            },
            copyForm() {
                return cloneDeep( this.form );
            },
        },
        watch: {
            value: {
                deep: true,
                handler() {
                    if ( this.isDiff ) {
                        this.form = cloneDeep( this.value );
                    }
                }
            },
            form: {
                deep: true,
                handler() {
                    if ( this.isDiff ) {
                        this.$emit( 'input', this.form );
                    }
                }
            },
            copyForm: {
                deep: true,
                handler( before, after ) {
                    if (
                        after && JSON.stringify( before.calculationType ) !== JSON.stringify( after.calculationType )
                    ) {
                        this.form.config = [];

                        if ( !this.form.calculationType ) {
                            return null;
                        }

                        forEach(
                            this.form.calculationType.attributes,
                            attribute => this.form.config.push( { attribute: attribute, value: null } )
                        );
                    }
                }
            }
        },
        data() {
            return { form: null };
        }
    }
</script>

<style lang='sass' scoped>
    .row-margin
        margin-top: 10px !important

    .container-margin
        margin-bottom: 10px !important
</style>
