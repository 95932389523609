<template>
    <label v-if="calculationTypes">
        <multi-select
            label='name'
            select-label=""
            selected-label=""
            deselect-label=""
            :multiple="false"
            :searchable="false"
            :show-labels="true"
            :disabled='disabled'
            v-model="currentValue"
            :close-on-select="true"
            placeholder="Тип расчета"
            :options="calculationTypes"
        ></multi-select>
    </label>
</template>

<script>
    import cloneDeep from 'lodash/cloneDeep';
    import MultiSelect from 'vue-multiselect';

    import { SALARY_ENDPOINT } from '../../utils/endpoints';

    export default {
        name: "SalaryBonusCalculationTypeSelect",
        components: { MultiSelect },
        props: {
            value: { type: Object, default: () => null },
            disabled: { type: Boolean, default: false }
        },
        watch: {
            value() {
                if ( this.isDiff()) {
                    this.setValue();
                }
            },
            currentValue() {
                if ( this.isDiff() ) {
                    this.$emit( 'input', this.currentValue );
                }
            }
        },
        data() {
            return {
                currentValue: null,
                calculationTypes: []
            };
        },
        methods: {
            isDiff() {
                return JSON.stringify( this.value ) !== JSON.stringify( this.currentValue );
            },
            setValue() {
                this.currentValue = cloneDeep( this.value );
            },
            fetchCalculationTypes() {
                this.calculationTypes = [];

                let params = { with: [ 'attributes', 'salaryBonusAddition', 'salaryBonusCheckType' ] };

                this.$http
                    .get( SALARY_ENDPOINT + '/bonus/calculation-types', { params } )
                    .then( response => this.calculationTypes = response.data.data.data )
                    .then( () => this.setValue() );
            }
        },
        created() {
            this.fetchCalculationTypes();
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
